<template>
  <div>
    <searchingBar :components="['filter', 'pages']"
                  :sortByOptions="sortByOptions"
                  @Fectch="FetchTable"
                  @filterValue="filterValue = $event"
                  @sortValue="tableObj.sortValue = $event"
                  @pageSize="tableObj.pagination.pageSize = $event"
                  @currentPageValue="tableObj.currentPage = $event"
    />
    <div v-show="transactionType === 'general'">
      <div class='row'>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <b-form-group id="family-group" label="Plantas" label-for="plants">
            <a-select v-model="filterPlant" @change="FetchTable()" allowClear
                      placeholder="Todas las Plantas" style="width: 100%"
                      show-search :filter-option="filterOption">
              <a-select-option v-for="option in plantsCombo" :key="option.id"
                                :value="option.id">
                {{ option.name }}
              </a-select-option>
            </a-select>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <b-form-group id="family-group" label="Familia" label-for="family">
            <a-select v-model="filterFamily" @change="FetchTable()" allowClear
                      placeholder="Todas las Familias" style="width: 100%"
                      show-search :filter-option="filterOption">
              <a-select-option v-for="option in familiesCombo" :key="option.id"
                                :value="option.id">
                {{ option.name }}
              </a-select-option>
            </a-select>
          </b-form-group>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <b-form-group id="family-group" label="Status" label-for="Status">
            <a-select v-model="filterEstatus" @change="FetchTable()" allowClear
                      placeholder="Todas los Status" style="width: 100%"
                      show-search :filter-option="filterOption">
              <a-select-option v-for="option in statusCombo" :key="option.id"
                                :value="option.id">
                {{ option.name }}
              </a-select-option>
            </a-select>
          </b-form-group>
        </div>
      </div>
    </div>
    <a-table :columns="tableObj.columns"
             :data-source="tableObj.data"
             :pagination="tableObj.pagination"
             :loading="tableObj.loading"
             @change="FetchTable"
             :row-key="record => record.id">
      <!-- <template slot="detail" slot-scope="data">
        <a-descriptions :title="`Reactivo: ${data.code} - ${data.name}`"
                        bordered
                        :size="size"
                        :column="{ sm: 1, md: 2, lg: 3}"
        >
          <a-descriptions-item label="Familia:" :size="size">
            <span class="small font-weight-bold">
              {{ data.families.name }}
            </span>
          </a-descriptions-item>
          <a-descriptions-item label="Mínimo:" :size="size">
            <span class="small font-weight-bold">
              {{ data.min }}
            </span>
          </a-descriptions-item>
          <a-descriptions-item label="Máximo:" :size="size">
            <span class="small font-weight-bold">
              {{ data.max }}
            </span>
          </a-descriptions-item>
          <a-descriptions-item label="Hoja de Seguridad" :size="size">
            <b-icon icon="check" variant="success" font-scale="2" v-if="data.security_sheet != null && data.security_sheet.length > 0"/>
            <b-icon icon="x" variant="warning" font-scale="2" v-else/>
          </a-descriptions-item>
          <a-descriptions-item label="Especificación Técnica" :size="size">
            <span class="font-weight-bold small">
              {{data.technical_specification}}
            </span>
          </a-descriptions-item>
          <a-descriptions-item label="Existencia:" :size="size" v-if="transactionType === 'Salida'">
            <span :class="(data.min > 0) ? textDanger : textSuccess">
              0
            </span>
          </a-descriptions-item>
        </a-descriptions>
      </template> -->
      <template slot="nombreReactivo" slot-scope="record">
        <div class="row text-center">
          <div class="col">
            <div class="h6" style="color:black">
              {{`Reactivo: ${record.code} - ${record.name}`}}
            </div>
          </div>
        </div>
      </template>
      <template slot="planta" slot-scope="record">
        <div class="row text-center medium">
          <div class="col">
            <div>
              {{`${record.work_centers.code} - ${record.work_centers.name}`}}
            </div>
          </div>
        </div>
      </template>
      <template slot="family" slot-scope="record">
        <div class="row text-center medium">
          <div class="col">
            <div>
              {{`${ (familiesCombo.find(e => e.id === record.families.id)) ? familiesCombo.find(e => e.id === record.families.id).name : '' }`}}
            </div>
          </div>
        </div>
      </template>
      <template slot="detallesReactivo" slot-scope="record">
        <div class="text-center">
          <small>
            <strong>
              Máximo:
            </strong>
          </small>
          <small>
            {{ record.max }}
          </small>
        </div>
        <div class="text-center">
          <small>
            <strong>
              Mínimo:
            </strong>
          </small>
          <small>
            {{ record.min }}
          </small>
        </div>
        <hr class="bg-primary">
        <div class="text-center small">
          {{'Hoja de Seguridad:  '}}
            <b-icon icon="check" variant="success" font-scale="2" v-if="record.security_sheet != null && record.security_sheet.length > 0"/>
            <b-icon icon="x" variant="warning" font-scale="2" v-else/>
        </div>
        <br>
        <div class="text-center small">
            {{'Especificación Técnica:  '}}
          <span class="font-weight-bold">
            {{record.technical_specification}}
          </span>
        </div>
      </template>
      <template slot="stockValue" slot-scope="record">
        <div class="row text-center medium">
          <div class="col">
            <div>
              {{ (record.reagent_existence) }}
            </div>
          </div>
        </div>
      </template>
      <!-- <template slot="action" slot-scope="data">
        <b-button  variant="secondary" @click="OpenModal(data)" pill>
          <div class="d-none d--block" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/> Editar
          </div>
          <div class="d--none" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/>
          </div>
        </b-button>
      </template> -->
      <template slot="nombreReactivo" slot-scope="record">
        <div class="row text-center">
          <div class="col">
            <div class="h6" style="color:black">
              {{`Reactivo: ${record.code} - ${record.name}`}}
            </div>
          </div>
        </div>
      </template>
      <template slot="action" slot-scope="data">
        <div v-show="transactionType === 'Entrada'">
          <b-button :disabled="data.status"  variant="warning"
                    @click="openModal(data)"
                    pill
          >
            <div class="d-none d--block"
                 v-b-tooltip.hover
                 title="Añadir"
            >
              <b-icon icon="cart-plus-fill"/> Añadir
            </div>
            <div class="d--none"
                 v-b-tooltip.hover
                 title="Añadir"
            >
              <b-icon icon="cart-plus-fill"/>
            </div>
          </b-button>
        </div>
        <div v-show="transactionType === 'Salida'">
          <b-button :disabled="data.status" variant="warning"
                    @click="openModal(data)"
                    pill
          >
            <div class="d-none d--block"
                 v-b-tooltip.hover
                 title="Añadir"
            >
              <b-icon icon="cart-plus-fill"/> Añadir
            </div>
            <div class="d--none"
                 v-b-tooltip.hover
                 title="Añadir"
            >
              <b-icon icon="cart-plus-fill"/>
            </div>
          </b-button>
        </div>
        <div v-show="transactionType === 'general'">
          <div v-if="data.stockStatus=='Reactivo disponible'">
            <small class="text-success">
              <strong>{{ data.stockStatus }}</strong>
            </small>
          </div>
          <div v-else>
            <small class="text-danger">
              <strong>{{ data.stockStatus }}</strong>
            </small>
          </div>
        </div>
      </template>
    </a-table>

    <b-modal size="xl"
             :title="(transactionType === 'Entrada') ? 'Añadir Entrada' : 'Añadir Salida'"
             v-model="showModal"
    >
      <b-container fluid>
        <div class="row">
          <div class="col-12 text-center">
            <h4>Reactivo: {{ modalForm.reagent_name }}</h4>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h6 class="font-weight-bold">Añadir {{ transactionType }}</h6>
              </div>
            </div>
            <hr class="bg-primary">
            <a-row class="row">
              <a-form-model layout="horizontal" :model="reagentCartData" :rules="rules" ref="rule-Form">
                <a-row>
                  <a-col :span="24">
                    <a-form-model-item label="Número de reactivos de la transacción:"
                                       class="mx-1"
                                       ref="quantity"
                                       prop="quantity"
                    >
                      <a-input-number v-model="reagentCartData.quantity"
                                      style="width: 100%"
                                      :min="0"
                                      :max="1000000"
                                      :step="1"
                      />
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="24">
                    <a-form-model-item label="Notas especiales del reactivo:"
                                       class="mx-1"
                                       ref="special_notes"
                                       prop="special_notes"
                    >
                      <a-textarea placeholder="Ingrese las notas especiales del reactivo"
                                  :rows="4"
                                  v-model="reagentCartData.special_notes"
                                  style="width: 100%"
                      />
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <div v-show="transactionType === 'Entrada'">
                  <a-row>
                    <a-col :span="24">
                      <a-form-model-item label="Notas sobre el volumen del reactivo:"
                                        class="mx-1"
                                        ref="container_volume_notes"
                                        prop="container_volume_notes"
                      >
                        <a-input placeholder="Ingrese el volumen del reactivo"
                                    v-model="reagentCartData.container_volume_notes"
                                    style="width: 100%"
                        />
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                </div>
              </a-form-model>
            </a-row>
            <div class="row">
              <div class="col-6 offset-3 text-center">
                <responsive-button responsive="xl"
                                   variant="success"
                                   icon="archive-fill"
                                   text="Guardar"
                                   @ClickEvent="submit"
                                   block
                                   pill
                />
              </div>
            </div>
            <div class="row pt-4">
              <div class="col-12">
                <h6 class="font-weight-bold">Información del reactivo</h6>
              </div>
            </div>
            <hr class="bg-primary">
            <div class="row">
              <div class="col-3 text-center">
                <span class="font-weight-bold">Mínimo: </span>
                <span>{{ modalForm.min }}</span>
              </div>
              <div class="col-3 text-center">
                <span class="font-weight-bold">Máximo: </span>
                <span>{{ modalForm.max }}</span>
              </div>
              <div class="col-3 text-center">
                <span class="font-weight-bold">Familia: </span>
                <span>{{ modalForm.family_name }}</span>
              </div>
              <div class="col-3 text-center">
                <span class="font-weight-bold">Existencias: </span>
                <span>{{ modalForm.reagent_existence }}</span>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12">
                <span class="font-weight-bold">Máximo: </span>
                <span class="text-justify small">{{ modalForm.reagent_existence }}</span>
              </div>
            </div> -->
            <div class="row pt-4">
              <div class="col-12">
                <span class="font-weight-bold">Especificación Técnica: </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <span class="text-justify small">{{ modalForm.technical_specification }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="closeModal"/>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import { Modal } from 'ant-design-vue'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'reagentInputTable',
  data() {
    return {
      size: 'small',
      // **** Table variables ****
      textSuccess: 'font-weight-bold text-success',
      textDanger: 'font-weight-bold text-danger',
      filterValue: '',
      filterFamily: undefined,
      filterPlant: undefined,
      filterStatus: undefined,
      filterEstatus: undefined,
      enableControl: false,
      // Combos de formulario
      familiesCombo: [],
      plantsCombo: [],
      statusCombo: [
        {
          id: 1,
          name: 'Reactivo Disponible',
        },
        {
          id: 2,
          name: 'Reactivo por debajo del mínimo',
        },
      ],
      sortByOptions: [],
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Nombre Reactivo',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'nombreReactivo' },
            width: '23%',
            // width: '85%',
            // children: [
            //   {
            //     // title: 'Nombre Reactivo',
            //     dataIndex: '',
            //     key: 'nombreReactivo',
            //     scopedSlots: { customRender: 'nombreReactivo' },
            //     class: 'text-center',
            //     // children: [
            //     //   {
            //     //     // title: 'Planta',
            //     //     dataIndex: 'families.name',
            //     //     key: 'families.name',
            //     //     class: 'text-center',
            //     //   },
            //     //   // {
            //     //   //   title: 'Familia',
            //     //   // },
            //     //   // {
            //     //   //   title: 'Límites',
            //     //   // },
            //     //   // {
            //     //   //   title: 'Especificaciones',
            //     //   // },
            //     // ],
            //   },
            // ],
          },
          {
            title: 'Planta',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'planta' },
            width: '11%',
          },
          {
            title: 'Familia',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'family' },
            width: '14%',
          },
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'detallesReactivo' },
            width: '34%',
          },
          {
            title: 'Existencias',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'stockValue' },
            width: '9%',
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center',
            scopedSlots: { customRender: 'action' },
            // fixed: 'right',
            width: '15%',
          },
        ],
      },
      resourceObj: {
        resourceType: 'reagents',
        form: {
          id: '',
          families: undefined,
          code: '',
          name: '',
          technical_specification: '',
          min: 0,
          max: 0,
          securitySheetArray: [],
        },
        relationships: ['families'],
      },
      // **** Table variables ****
      // **** Modal variables ****
      showModal: false,
      modalForm: {
        id: '0',
        reagent_name: '',
        family_name: '',
        min: 0,
        max: 0,
        technical_specification: '',
        reagent_existence: 0,
      },
      reagentCartData: {
        id: 0,
        reagent_id: '0',
        reagent_name: '',
        reagent_family: '',
        quantity: 0,
        special_notes: '',
        container_volume_notes: '',
      },
      rules: {
        reagent_id: [
          { required: true, message: 'El campo de reactivo es obligatorio', trigger: 'blur' },
        ],
        quantity: [
          { required: true, message: 'El campo de cantidad de reactivos es obligatorio', trigger: 'blur' },
        ],
      },
      // **** Modal variables ****
    }
  },
  props: {
    tableType: {
      required: true,
      type: [String],
    },
    transactionType: {
      default: 'Entrada',
      type: [String],
      required: true,
    },
  },
  mixins: [fractalMixin],
  computed: {
    ...mapState('reagents', ['state']),
    stateTransactionReagents () {
      return this.$store.state.reagents.transactionReagents
    },
  },
  methods: {
    ...mapActions('reagents', ['STORE_REAGENT_DATA']),
    async FetchTable(pagEvent = {}) {
      const shiftArray = []
      // const params = {
      //   'page[number]': (Object.keys(pagEvent).length !== 0) ? `${this.tableObj.pagination.current}` : `${this.tableObj.currentPage}`,
      //   'page[size]': `${this.tableObj.pagination.pageSize}`,
      //   // sort: this.tableObj.sortValue,
      //   include: 'families',
      // }
      // if (this.filterValue !== '') params['filter[global]'] = this.filterValue
      // await this.GetTableResource(pagEvent, this.resourceObj.resourceType, this.tableObj, params)
      const params = {
        page: (Object.keys(pagEvent).length !== 0) ? `${this.tableObj.pagination.current}` : `${this.tableObj.currentPage}`,
        pageSize: `${this.tableObj.pagination.pageSize}`,
        orderField: 'name',
        orderType: 'asc',
      }
      if (this.filterPlant !== '') params.workCenterId = this.filterPlant
      if (this.filterFamily !== '') params.familyId = this.filterFamily
      if (this.filterValue !== '') params.search = this.filterValue
      // await this.GetTableResource(pagEvent, this.resourceObj.resourceType, this.tableObj, params)
      await this.GetTableResourcev2(pagEvent, this.resourceObj.resourceType, this.tableObj, params)
      this.tableObj.data.forEach(element => {
        if (element.reagent_existence < element.min) {
          element.stockStatus = 'Reactivo por debajo del mínimo'
        } else {
          element.stockStatus = 'Reactivo disponible'
        }
        shiftArray.push(element)
      })
      this.tableObj.data = []
      this.tableObj.data = shiftArray
      if (this.filterEstatus !== undefined) {
        if (this.filterEstatus === 1) {
          this.tableObj.data = this.tableObj.data.filter(x => x.stockStatus === 'Reactivo disponible')
        } else {
          this.tableObj.data = this.tableObj.data.filter(x => x.stockStatus === 'Reactivo por debajo del mínimo')
        }
        // this.tableObj.pagination.total = this.tableObj.data.length
      }
    },
    openModal(data = {}) {
      // Assign all the data in information modal
      this.showModal = true
      this.modalForm.id = data.id
      const reagentName = data.code + ' ' + data.name
      const reagentFamily = data.families.name
      this.modalForm.reagent_name = reagentName
      this.modalForm.min = data.min
      this.modalForm.max = data.max
      this.modalForm.family_name = reagentFamily
      this.modalForm.technical_specification = data.technical_specification
      this.modalForm.reagent_existence = data.reagent_existence
      // Assign data to reagentCart
      this.reagentCartData.id = this.$store.state.reagents.transactionReagents.length + 1
      this.reagentCartData.reagent_id = data.id
      this.reagentCartData.reagent_name = reagentName
      this.reagentCartData.reagent_family = reagentFamily
    },
    closeModal() {
      this.reagentCartData.id = this.$store.state.reagents.transactionReagents.length + 1
      this.reagentCartData.reagent_id = '0'
      this.reagentCartData.quantity = 0
      this.reagentCartData.reagent_name = ''
      this.reagentCartData.reagent_family = ''
      this.reagentCartData.special_notes = ''
      this.reagentCartData.container_volume_notes = ''
      this.showModal = false
    },
    submit() {
      // const shiftArray = []
      // Validate zero in quantity
      if (this.transactionType === 'Entrada') {
        if (this.reagentCartData.quantity === 0) {
          this.errorMessage('La cantidad de reactivos debe ser mayor a 0.')
          return 0
        }
        // Validate maximum data
        if (this.reagentCartData.quantity + this.modalForm.reagent_existence > this.modalForm.max) {
          this.errorMessage('La cantidad ingresada debe ser menor al valor máximo definido. Favor de verificar el número de reactivos.')
          return 0
        }
        // Send data to vuex
        this.modalForm.reagent_existence = this.modalForm.reagent_existence + this.reagentCartData.quantity
        this.tableObj.data.find(x => x.id === this.modalForm.id).reagent_existence = this.modalForm.reagent_existence
        this.tableObj.data.find(x => x.id === this.modalForm.id).status = true
        this.$store.dispatch('reagents/STORE_REAGENT_DATA', { ...this.reagentCartData })
        this.successMessage('¡Reactivo añadido!', 'Se ha añadido correctamente el reactivo al carrito')
        this.closeModal()
      } else {
        // console.log('Existences', this.modalForm.reagent_existence)
        if (this.reagentCartData.quantity === 0) {
          this.errorMessage('La cantidad de reactivos debe ser mayor a 0.')
          return 0
        }
        // Validate maximum data
        // if (this.reagentCartData.quantity > this.modalForm.max) {
        //   this.errorMessage('La cantidad máxima es menor al valor ingresado. Favor de verificar el número de reactivos.')
        //   return 0
        // }
        // Validate existence different than zero
        if (this.modalForm.reagent_existence - this.reagentCartData.quantity < 0) {
          this.errorMessage('La transacción resultaría en existencias negativas. Favor de verificar el número de reactivos a dar salida.')
          return 0
        }
        // Send data to vuex
        // console.log('Existences antes: ', this.modalForm.reagent_existence)
        this.modalForm.reagent_existence = this.modalForm.reagent_existence - this.reagentCartData.quantity
        this.tableObj.data.find(x => x.id === this.modalForm.id).reagent_existence = this.modalForm.reagent_existence
        console.log('Formulario: ', this.modalForm)
        this.tableObj.data.find(x => x.id === this.modalForm.id).status = true
        this.$store.dispatch('reagents/STORE_REAGENT_DATA', { ...this.reagentCartData })
        this.successMessage('¡Reactivo añadido!', 'Se ha añadido correctamente el reactivo al carrito')
        this.closeModal()
        // console.log('Table después: ', this.tableObj.data)
        // this.tableObj.data
      }
    },
    updateValue(data) {
      if (this.transactionType === 'Entrada') {
        let shiftArray = []
        this.tableObj.data.find(x => (x.id === data.reagent_id)).reagent_existence -= data.quantity
        this.tableObj.data.find(x => x.id === this.modalForm.id).status = false
        shiftArray = this.tableObj.data
        this.tableObj.data = []
        this.tableObj.data = shiftArray
      } else {
        let shiftArray = []
        this.tableObj.data.find(x => x.id === data.reagent_id).reagent_existence += data.quantity
        this.tableObj.data.find(x => x.id === this.modalForm.id).status = false
        shiftArray = this.tableObj.data
        this.tableObj.data = []
        this.tableObj.data = shiftArray
      }
    },
    async fetchTablePlant(data, pagEvent = {}) {
      const shiftArray = []
      // const ex = 0
      const params = {
        page: (Object.keys(pagEvent).length !== 0) ? `${this.tableObj.pagination.current}` : `${this.tableObj.currentPage}`,
        pageSize: `${this.tableObj.pagination.pageSize}`,
        orderField: 'name',
        orderType: 'asc',
      }
      if (this.data !== '') params.workCenterId = this.plantsCombo.find(e => e.code === data).id
      // await this.GetTableResource(pagEvent, this.resourceObj.resourceType, this.tableObj, params)
      await this.GetTableResourcev2(pagEvent, this.resourceObj.resourceType, this.tableObj, params).then(
        () => {
          this.tableObj.data.forEach(element => {
            if (element.reagent_existence < element.min) {
              element.stockStatus = 'Reactivo por debajo del mínimo'
            } else {
              element.stockStatus = 'Reactivo disponible'
            }
            shiftArray.push(element)
          })
          this.tableObj.data = []
          this.tableObj.data = shiftArray
          this.$store.state.reagents.transactionReagents.forEach(element => {
            if (this.transactionType === 'Entrada') {
              if (this.tableObj.data.find(x => x.id === element.reagent_id)) {
                this.tableObj.data.find(x => x.id === element.reagent_id).reagent_existence += element.quantity
                this.tableObj.data.find(x => x.id === element.reagent_id).status = true
              }
            } else if (this.transactionType === 'Salida') {
              if (this.tableObj.data.find(x => x.id === element.reagent_id)) {
                this.tableObj.data.find(x => x.id === element.reagent_id).status = true
                this.tableObj.data.find(x => x.id === element.reagent_id).reagent_existence -= element.quantity
              }
            }
          })
        },
      )
    },
    errorMessage(message) {
      Modal.error({
        title: '¡Ha ocurrido un error!',
        content: message,
        okText: 'Ok',
        centered: true,
        zIndex: 3000,
      })
    },
    successMessage(title, message) {
      Modal.success({
        title: title,
        content: message,
        okText: 'Aceptar',
        okType: 'primary',
        zIndex: 3000,
      })
    },
    async fetchCombos() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/workCenters',
          params: params1,
        },
        {
          path: '/fetch/families?pageSize=1000',
          params: params1,
        },
      ]
      const [plants, families] = await this.GetConcurrencyResources(arr)
      this.plantsCombo = plants
      this.familiesCombo = families
      if (this.transactionType === 'general') {
        // this.tableObj.columns.pop()
        this.tableObj.columns[this.tableObj.columns.length - 1].title = 'Status'
      }
    },
  },
  async mounted() {
    await this.fetchCombos()
    await this.FetchTable()
  },
}
</script>
